<template>
    <svg
        aria-hidden="true"
        class="icon flex-shrink-0 flex-grow-0 inline-block"
        role="img"
        :aria-label="name"
        focusable="false"
        :class="{ 'sm': size === 'sm' }"
        :style="{ color: color }"
    >
        <use :href="symbolId" fill="currentColor" />
    </svg>
</template>

<script lang="ts" setup>
    import { PropType } from 'vue';

    const props = defineProps({
        name: String,
        color: {
            type: String,
            default: undefined,
        },
        size: {
            type: String as PropType<'sm' | 'md'>,
            default: 'md',
        },
        animated: {
            type: Boolean,
            default: false,
        },
    });

    const symbolId = computed(() => `#icon-${props.name}`);
</script>

<style scoped>
    .icon {
        --size: 16px;

        transition: transform 300ms linear;
        width: var(--size);
        height: var(--size);

        &.sm {
            @apply transform scale-[0.875];
        }
    }
</style>
